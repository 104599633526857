<template>
  <div class="news-box ">
    
   
    <div class="index-news-box">
      <p class="index-news-title">
        全部资讯
      </p>
      
      <div class="index-news-type1" v-for="(a,index) in newsList" :key='index'>
        <img :src="a.cover" alt="" @click="toGo('/article',{id:a.id})">
      </div>

      
      
      
    </div>

    
    
  </div>
</template>

<script>
import {selectNewsAll} from "../utils/api";
export default {
  components: {
    
  },
  data() {
    return {
      active: 1,
      total: 4,
      headerShow:true,
      showNav:true,
      navShow:true,
      wrapScroll: {},
      newsList:null,
    }
    
  },
  
  created(){
    
    
  },
  mounted() {
    // setTimeout(()=>{
    //   this.initscroll();
    // },20)
    this.selectNewsAll()
    
  },
  methods: {
    selectNewsAll(){
      selectNewsAll()
      .then((res) => {
        
       
        this.newsList=res.pageInfo.records;
        
       
        
      })
    },
  },
};
</script>

<style scoped lang="scss">
.news-box{

  .index-news-box{
    border-bottom:1px solid #fff ;
    border-top:1px solid #fff ;
    padding:5vh 40px;
    background-color: #fff;
    .index-news-title{
      font-size: 26px;
      margin-bottom: 40px;
    }
    h1{
      margin: 20px 0 5px;
      font-size: 24px;
    }
    
    .index-news-type1{
      width: 100%;
      margin-bottom: 80px;
      img{
        border:1px solid #f5f5f5;
      }
      .img{
        width: 100%;
        height: 400px;
        background: url('../assets/images/index/news.png') no-repeat;
        background-size:cover ;
      }
    }
    
    .index-news-type2{
      margin-bottom: 80px;
      display: flex;
      .index-news-type2-img{
        flex:1;
        display: flex;
        flex-direction:column;
        .index-news-type2-img-one{
          flex:1;
        }
        img{
          display: block;
          height: auto;
        }
        
      }
      .index-news-type2-img1{
        img{
          border:1px solid #f5f5f5;
        }
      }
      .index-news-type2-img2{
        margin-left: 2px;
        border:1px solid #f5f5f5;
      }
      
    }
  }
  
}
</style>
